exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-delete-tsx": () => import("./../../../src/pages/delete.tsx" /* webpackChunkName: "component---src-pages-delete-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-slug-tsx": () => import("./../../../src/pages/{Page.slug}.tsx" /* webpackChunkName: "component---src-pages-page-slug-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-registered-tsx": () => import("./../../../src/pages/registered.tsx" /* webpackChunkName: "component---src-pages-registered-tsx" */),
  "slice---src-components-footer-tsx": () => import("./../../../src/components/footer.tsx" /* webpackChunkName: "slice---src-components-footer-tsx" */),
  "slice---src-components-header-tsx": () => import("./../../../src/components/header.tsx" /* webpackChunkName: "slice---src-components-header-tsx" */)
}

